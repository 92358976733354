//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
// import { i18n } from '@/vueI18n';
import moment from 'moment';

import { TripPermissions } from '@/modules/trip/trip-permissions';
import { TripModel } from '@/modules/trip/trip-model';
const { fields } = TripModel;

export default {
  name: 'app-trip-list-table',
  props: ['status'],

  mounted() {
    this.doFetchFirstPage();
    this.doMountTable(this.$refs.table);
  },
  data() {
    return {
      filter: '',
      columns: [
        {
          name: 'tripNumber',
          field: 'tripNumber',
          label: 'entities.trip.fields.tripNumber',
          align: 'center',
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.trip.fields.name',
          align: 'center',
        },
        {
          name: 'capacity',
          field: 'capacity',
          label: 'entities.trip.fields.capacity',
          align: 'center',
        },
        {
          name: 'day',
          field: 'tripTime',
          label: 'common.day',
          align: 'center',
        },
        {
          name: 'tripTime',
          field: 'tripTime',
          label: 'common.date',
          align: 'center',
        },
        {
          name: 'time',
          field: 'tripTime',
          label: 'common.time',
          align: 'center',
        },
        {
          name: 'createdBy',
          field: 'createdBy',
          label: 'entities.trip.fields.createdBy',
          align: 'center',
        },
        {
          name: 'type',
          field: 'type',
          label: 'entities.trip.fields.type',
          align: 'center',
        },
        { 
          name: 'action', 
          field: 'action', 
          label: 'common.action', 
          align: 'center', 
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'trip/list/rows',   
      count: 'trip/list/count',   
      loading: 'trip/list/loading',
      pagination: 'trip/list/pagination',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new TripPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new TripPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.total || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination['rowsPerPage']);
    },
  },

  methods: {
    ...mapActions({
      doMountTable: 'trip/list/doMountTable',
      doDestroy: 'trip/destroy/doDestroy',
      doChangeSort: 'trip/list/doChangeSort',
      doChangePaginationCurrentPage: 'trip/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'trip/list/doChangePaginationPageSize',
      doFetch: 'trip/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenterCapacity(row) {
      const val = TripModel.presenter(row, 'capacity');
      return val ? (val?.min ?? 0) + '-' + (val?.max ?? 0) : 'ـــ';
    },
    presenter(row, fieldName) {
      const val = TripModel.presenter(row, fieldName);
      if (fieldName == 'tripNumber') {
        return val 
          ? this.currentLanguageCode == 'ar' ? `${val} #` : `# ${val}`
          : '___'
      }
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : '';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = TripModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterOwner(row, fieldName, key) {
      const owner = TripModel.presenter(row, fieldName);
      let fieldValue = owner && owner[key] ? owner[key] : null
      let value
      switch (key) {
        case 'name': 
          value = fieldValue && fieldValue[this.currentLanguageCode] ? fieldValue[this.currentLanguageCode] : 'ـــ'
          break
        case 'avatar':
          value = fieldValue
            ? fieldValue
            : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c';
          break
        default:
          value = fieldValue
          break
      }
      return value ? value : 'ـــ';
    },
    presenterGuide(row, fieldName, key) {
      const user = TripModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = TripModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },

    // async doFilter() {
    //   const filter = { status: this.status };
    //   return this.doFetch({
    //     filter,
    //   });
    // },

    async doFetchFirstPage() {
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: { sortBy: 'desc', limit: 10 }
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: firstDocument,
          action: 'prev'
        },
        keepPagination: true,
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      return this.doFetch({
        filter: { status: this.status },
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
          doc: lastDocument,
          action: 'next'
        },
        keepPagination: true,
      });
    },
  },
};
